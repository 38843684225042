import React from 'react';
import FadeInWhenVisible from '~/components/FadeInWhenVisible';
import { motion, AnimatePresence } from 'framer-motion';

export default function FaqItem({ q, a, idx, expanded, setExpanded }) {
  const isOpen = idx === expanded;

  return (
    <FadeInWhenVisible className="border-b border-b-[#D1D7E0] py-7">
      <header
        className="grid grid-cols-12 items-center group cursor-pointer"
        onClick={() => setExpanded(isOpen ? false : idx)}
      >
        <h5 className="text-h5 col-span-10 md:col-span-8">{q}</h5>
        <div
          className={` w-10 h-10 ml-auto md:mr-0 md:w-12 md:h-12 rounded-full bg-transparent border border-navy col-span-2 md:col-span-1 col-start-11 md:col-start-12 flex justify-end relative transform transition-all duration-300 ${
            isOpen && 'bg-blue border-blue rotate-45'
          } `}
        >
          <div
            className={`absolute h-px w-6 bg-navy top-1/2 left-1/2 tranform -translate-x-1/2 -translate-y-1/2 transition-colors duration-300 ${
              isOpen && '!bg-white'
            } `}
          ></div>
          <div
            className={`absolute h-px w-6 bg-navy top-1/2 left-1/2 tranform -translate-x-1/2 -translate-y-1/2 rotate-90 transition-colors duration-300 ${
              isOpen && '!bg-white'
            } `}
          ></div>
        </div>
      </header>
      <AnimatePresence>
        <motion.div
          key="content"
          initial="collapsed"
          animate={isOpen ? 'open' : 'collapsed'}
          exit="collapsed"
          variants={{
            open: { opacity: 1, height: 'auto' },
            collapsed: { opacity: 0, height: 0 },
          }}
          transition={{ duration: 0.3 }}
        >
          <div className="py-4 grid grid-cols-12">
            <div className="col-span-10 md:col-span-8" dangerouslySetInnerHTML={{ __html: a }} />
          </div>
        </motion.div>
      </AnimatePresence>
    </FadeInWhenVisible>
  );
}
