import React from "react";
import TextLink from '~/components/atoms/TextLink'
import FaqItems from '~/components/molecules/FaqItems';
import TextCard from '~/components/molecules/TextCard';
import FadeInWhenVisible from '~/components/FadeInWhenVisible'
import FAQsSEO from "~/components/seo/FAQsSEO";

export default function Faqs({ key, index, ...props }) {

  const section = props.faqs

  return (
    <>
      <FAQsSEO faqs={section.faqs} />
      <section id={section.fieldGroupName + index} className="my-20 md:my-36">
        <div className="container">
          <header className="max-w-lg mx-auto text-center flex flex-col items-center mb-18">
            <TextCard {...section.textCard} />
          </header>
          <div className="max-w-5xl mx-auto mb-16">
            <FaqItems {...section} />
          </div>
          {section.link && (
            <FadeInWhenVisible className="flex justify-center">
              <TextLink {...section.link} />
            </FadeInWhenVisible>
          )}
        </div>
      </section>
    </>
  );
}

