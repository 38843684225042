import React from 'react'
import { Helmet } from "react-helmet"

const FAQsSEO = ({ faqs }) => {

    return (
        <Helmet>
            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": [
                            ${(faqs??[]).map(({question, answer})=>(
                                `{
                                    "@type": "Question",
                                    "name": "${question}",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "${answer.replace(/"/g, '\\"').replace(/\n/g, '')}"
                                    }
                                }`
                            ))}
                        ]
                    }
                `}
            </script>
        </Helmet>
    )
}

export default FAQsSEO