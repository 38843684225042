import React, {useState} from "react";
import FaqItem from '~/components/molecules/FaqItem';
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function FaqItems({className, id, heading, ...section}) {

  const [expanded, setExpanded] = useState(false);

  return (
    <div className={` ${className}`} id={id}>
      <FadeInWhenVisible className="mb-4 md:mb-8">
        <h2 className="text-h2 ">{heading}</h2>
      </FadeInWhenVisible>
      {section.faqs.map((item, i) => (
        <FaqItem key={`faq${i}`} q={item.question} a={item.answer} idx={i} expanded={expanded} setExpanded={setExpanded}  />
      ))}
    </div>
  );
}

